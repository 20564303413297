import "./bootstrap";

// Por lo que sea, para que se detecte $ o jQuery, poner aquí los imports

import {
    Alert,
    Button,
    Carousel,
    Collapse,
    Dropdown,
    Modal,
    Offcanvas,
    Popover,
    ScrollSpy,
    Tab,
    Toast,
    Tooltip,
} from "bootstrap";

import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min";
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.es";
import "bootstrap-duallistbox/dist/jquery.bootstrap-duallistbox";
import "chart.js/dist/Chart.min";
import "chartjs-plugin-colorschemes/dist/chartjs-plugin-colorschemes.min";
import "chartjs-gauge/dist/chartjs-gauge.min";
import "chartjs-plugin-deferred/dist/chartjs-plugin-deferred.min";
import "chartjs-plugin-zoom/dist/chartjs-plugin-zoom.min";
import DataTable from "datatables.net-bs5";
import "datatables.net-fixedheader";
import "datatables.net-plugins/sorting/date-eu";
import "datatables.net-plugins/dataRender/ellipsis";
import "dropzone/dist/min/dropzone.min";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import GLightbox from "glightbox/dist/js/glightbox.min";
import "hammerjs/hammer.min";
import "jquery-sparkline";
import "jquery-sortable";
import moment from "moment";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import L from "leaflet/dist/leaflet";
import "leaflet.heat/dist/leaflet-heat";
import "leaflet-providers/leaflet-providers";
import "leaflet.fullscreen/Control.FullScreen";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.min";
import "tinymce/tinymce.min";
import PerfectScrollbar from "perfect-scrollbar";
import ProgressBar from "progressbar.js/dist/progressbar.min";
import screenfull from "screenfull";
import Sortable from 'sortablejs';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import Swal from "sweetalert2";
import select2 from "select2";

select2();
flatpickr.localize(Spanish);

window.Alert = Alert;
window.Button = Button;
window.Carousel = Carousel;
window.Collapse = Collapse;
window.DataTable = DataTable;
window.Dropdown = Dropdown;
window.flatpickr = flatpickr;
window.GLightbox = GLightbox;
window.Modal = Modal;
window.moment = moment;
window.monthSelectPlugin = monthSelectPlugin;
window.L = L;
window.Offcanvas = Offcanvas;
window.PerfectScrollbar = PerfectScrollbar;
window.Popover = Popover;
window.ProgressBar = ProgressBar;
window.rangePlugin = rangePlugin;
window.screenfull = screenfull;
window.ScrollSpy = ScrollSpy;
window.Sortable = Sortable;
window.Swal = Swal;
window.uuidv4 = uuidv4;
window.Yup = yup;
window.Tab = Tab;
window.Toast = Toast;
window.Tooltip = Tooltip;
